@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;800;900&display=swap');
@import './styles/global.css';

body {
  margin: 0;
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

#root {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
  height: 100dvh;
  width: 100%;
}

#root:has(.dashboard) {
  min-height: 100vh;
  height: auto;
}

#root:has(.dashboard) .monaco-editor {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  overflow: hidden;
}

#root:has(.dashboard) .monaco-editor.focused {
  border-color: transparent;
  outline: 3px solid #1976d2;
  outline-offset: -2px;
}

.grecaptcha-badge {
  visibility: hidden;
}
