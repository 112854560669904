@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --black: #09090b;
    --white: #ffffff;
    --brand-green-500: #05e292;
    --brand-green-600: #00bc76;

    --background: var(--white);
    --foreground: var(--black);

    --card: var(--white);
    --card-foreground: var(--black);

    --popover: var(--white);
    --popover-foreground: var(--black);

    --primary: var(--brand-green-500);
    --primary-foreground: var(--white);

    --secondary: var(--brand-green-600);
    --secondary-foreground: var(--white);

    --muted: #f1f5f9;
    --muted-foreground: #6e6e77;

    --accent: #f4f4f5;
    --accent-foreground: var(--base-black);

    --destructive: #ef4444;
    --destructive-foreground: #f8fafc;

    --border: #e2e8f0;
    --input: #c2c7ca;
    --ring: var(--secondary);

    --radius: 0.5rem;

    --font-sans: sofia-pro, sans-serif;
    --title-font: altivo, sans-serif;
    --hebrew-main: nitti-mostro-solid, sans-serif;

    /* --primary-50: #eefff8;
    --primary-100: #d6fff0;
    --primary-200: #b0ffe3;
    --primary-300: #73ffcd;
    --primary-400: #23f8ab;
    --primary-500: #05e292;
    --primary-600: #00bc76;
    --primary-700: #029360;
    --primary-800: #08734e;
    --primary-900: #095e42;
    --primary-950: #003524; */
    /* --secondary-500: #dd53f8; */

    /* --base-black: #181b1a; */
    /* --dark-grey: #2e2d2d;
    --light-grey: #f4f4f5;
    --medium-aquamarine: #61d593;
    --medium-sea-green: #3fd37f; */
  }
  body {
    @apply bg-background text-foreground;
  }
}
